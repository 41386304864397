<template>
  <div class="container-articleManagment">
    <header class="header">
      <van-nav-bar
        title="文章列表"
        left-arrow
        @click-left="handleBlack"
        safe-area-inset-top
      >
        <template #right>
          <!-- <van-icon name="search"  size="24" color="#333333" @click="loadSearchArticle"/> -->
          <icon-svg
            iconClass="iconsearch"
            @click="loadSearchArticle"
            style="height: 24px; width: 24px; color: '#333333'"
          />
        </template>
      </van-nav-bar>
    </header>
    <article>
      <!-- 一级 -->
      <div class="articleType">
        <van-tabs
          @click="loadType"
          v-model:active="oneTypeActive"
          line-width="10"
          line-height="2"
          color="#3366FF"
        >
          <van-tab v-for="item in typeList" :key="item.id" :title="item.text">
          </van-tab>
        </van-tabs>
      </div>
      <div class="twotypeselect">
        <div class="articleType-all" v-if="!showAdd">
          <van-tabs
              @click="loadArticleType"
              v-model:active="twoTypeTab"
              swipe-threshold="1"
              color="#FF6E01"
              title-active-color="#FF6E01"
          >
            <van-tab v-if="loadNiceChoice" title="精选"></van-tab>
            <van-tab
                v-for="it in allTypeList"
                :key="it.categoryPrimaryId"
                :title="it.materialCategoryName"
            ></van-tab>
            <!--            <van-tab v-if="showMore" title="更多"></van-tab>-->
          </van-tabs>
        </div>
        <div class="more-type" @click="handleMoreType" v-if="!showAdd">更多</div>
      </div>
      <!-- 二级 -->
<!--      <div class="articleType-all">-->
<!--        <van-tabs-->
<!--          @click="loadArticleType"-->
<!--          v-model:active="typeActive"-->
<!--          swipe-threshold="4"-->
<!--          color="#FF6E01"-->
<!--          title-active-color="#FF6E01"-->
<!--        >-->
<!--          <van-tab v-if="loadNiceChoice" title="精选"></van-tab>-->
<!--          <van-tab-->
<!--            v-for="it in allTypeList"-->
<!--            :key="it.typeId"-->
<!--            :name="it.typeId"-->
<!--            :title="it.typeName"-->
<!--          ></van-tab>-->
<!--          <van-tab v-if="showMore" title="更多"></van-tab>-->
<!--        </van-tabs>-->
<!--      </div>-->
    </article>

    <div class="articleManagenent-type" id="article">
      <van-pull-refresh
        @refresh="onRefresh"
        v-model="refreshing"
        style="min-height: 100%"
      >
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          offset="100"
          @load="onLoadMore"
          v-if="loadArticle"
        >
          <active-type
            v-if="loadArticle"
            :articleList="articles"
            :artType="(article_M = 1)"
            :twoType="typeActive"
            :oneType="oneTypeActive"
            :typesStr="articleTypeStr"
          ></active-type>
        </van-list>
      </van-pull-refresh>
    </div>

    <!-- 遮罩层 -->
    <van-overlay :show="showType">
      <div class="wrapper" @click.stop>
        <div class="select-like-type">
          <icon-svg
            iconClass="iconx"
            class="cancel"
            @click="loadCancelChoice"
          />
          <p class="title">选择你喜欢的类别</p>
          <div class="grid-type">
            <div
              :class="['grid-type-item', box.includes(it) ? 'bgColor' : '']"
              v-for="it in moreTypeList"
              :key="it.typeId"
              @click="handleChoice(it)"
            >
              {{ it.materialCategoryName }}
            </div>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
          <div class="confirm" @click="loadConfirm">确认</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import activeType from "./type/type.vue";
import { getManageArticleList,getMerchantMaterialFullCategory } from "../../api/articleManagement";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
export default {
   name:'articleMana',
  components: {
    activeType,
  },
   activated(){
    document.getElementById('article').scrollTop = Number(sessionStorage.getItem('scrollTop'))
  },
  deactivated(){
    let scrollTop = document.getElementById('article').scrollTop
    sessionStorage.setItem('scrollTop',scrollTop)
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      twoTypeTab:0,
      endIndex:10000,
      loadNiceChoice: false,
      loading: false,
      finished: false,
      showMore: false,
      showAdd: false,
      pages: 1,
      allTypeCurrent: 1,
      isMaterialCurrent: 1,
      notMaterialCurrent: 1,
      showArticleType: false,
      loadArticle: false,
      oneTypeActive: Number(route.params.oneType)
        ? Number(route.params.oneType)
        : 0,
      typeActive: Number(route.params.twoType)
        ? Number(route.params.twoType)
        : 0,
      articleTypeStr: route.params.typesStr ? route.params.typesStr : "",
      articles: {},
      activeName: "all",
      refreshing: false,
      typeList: [
        { id: 0, name: "all", text: "全部" },
        { id: 1, name: "notReviewed", text: "素材库" },
        { id: 2, name: "reviewed", text: "非素材库" },
      ],
      allTypeList: [],
      moreTypeList: [],
      box: [],
      articleType: false,
      showType: false,
    });

    //加载文章
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
      overlayClass: "load-cls",
    });
     //获取文章分类
    const loadArticleMoreType = async () => {
      let result = await getMerchantMaterialFullCategory({
        modeId:1,
        materialType:0,
      });
      if (result){
        state.moreTypeList = result;
        // state.allTypeList = result.slice(0, 4);
        state.allTypeList = result
        state.loadNiceChoice = true;
        state.showMore = true;
      }
    };
    loadArticleMoreType();
    const loadArticleList = async () => {
      if (state.articleTypeStr && state.typeActive === state.endIndex) {
        if (state.oneTypeActive === 0) {
          let data = await getManageArticleList({ types: state.articleTypeStr });
          state.articles = data.records;
          state.pages = data.pages;
        } else if (state.oneTypeActive === 1) {
          let data = await getManageArticleList({
            types: state.articleTypeStr,
            isMaterial: 1,
          });
          state.articles = data.records;
          state.pages = data.pages;
        } else if (state.oneTypeActive === 2) {
          let data = await getManageArticleList({
            // types: state.articleTypeStr,
            isMaterial: 0,
          });
          state.articles = data.records;
          state.pages = data.pages;
        }
        state.loadArticle = true;
      } else {
        if (state.oneTypeActive === 0) {
          if (state.typeActive === 0) {
            let data = await getManageArticleList();
            state.articles = data.records;
            state.pages = data.pages;
          } else {
            let data = await getManageArticleList({ types: state.typeActive });
            state.articles = data.records;
            state.pages = data.pages;
          }
        } else if (state.oneTypeActive === 1) {
          if (state.typeActive === 0) {
            let data = await getManageArticleList({
              isMaterial: 1,
            });
            state.articles = data.records;
            state.pages = data.pages;
          } else {
            let data = await getManageArticleList({
              types: state.typeActive,
              isMaterial: 1,
            });
            state.articles = data.records;
            state.pages = data.pages;
          }
        } else if (state.oneTypeActive === 2) {
          if (state.typeActive === 0) {
            let data = await getManageArticleList({
              isMaterial: 0,
            });
            state.articles = data.records;
            state.pages = data.pages;
          } else {
            let data = await getManageArticleList({
              // types: state.typeActive,
              isMaterial: 0,
            });
            state.articles = data.records;
            state.pages = data.pages;
          }
        }
        state.loadArticle = true;
      }
      Toast.clear();
    };
    loadArticleList();
    //打开更多分类查询
    const handleMoreType = () =>{
      loadArticleType(10000)
    }
    //二级分类查询
    const loadArticleType = async (val) => {
      if(val!==0&&val!==10000){
        state.allTypeList.map((it,index)=>{
          if(index+1===val){
            console.log("选中的分类",it)
            state.typeActive = it.categoryPrimaryId
          }
        })
      }else {
        if(val===0){
          state.typeActive = 0
        }else {
          state.typeActive = val
        }
      }
      if (state.oneTypeActive === 0) {
        if (state.typeActive === state.endIndex) {
          loadSelectType();
        } else {
          if (state.typeActive === 0) {
            let data = await getManageArticleList();
            state.articles = data.records;
            state.pages = data.pages;
          } else {
            let data = await getManageArticleList({
              types: state.typeActive,
            });
            state.articles = data.records;
            state.pages = data.pages;
          }
        }
        state.allTypeCurrent = 1;
        state.finished = false;
      } else if (state.oneTypeActive === 1) {
        if (state.typeActive === state.endIndex) {
          state.articles = {};
          loadSelectType();
        } else {
          if (state.typeActive === 0) {
            let data = await getManageArticleList({ isMaterial: 1 });
            state.articles = data.records;
            state.pages = data.pages;
          } else {
            let data = await getManageArticleList({
              types: state.typeActive,
              isMaterial: 1,
            });
            state.articles = data.records;
            state.pages = data.pages;
          }
        }
        state.isMaterialCurrent = 1;
        state.finished = false;
      } else {
        if (state.typeActive === state.endIndex) {
          state.articles = {};
          loadSelectType();
        } else {
          if (state.typeActive === 0) {
            let data = await getManageArticleList({ isMaterial: 0 });
            state.articles = data.records;
            state.pages = data.pages;
          } else {
            let data = await getManageArticleList({
              types: state.typeActive,
              isMaterial: 0,
            });
            state.articles = data.records;
            state.pages = data.pages;
          }
        }
        state.notMaterialCurrent = 1;
        state.finished = false;
      }
    };

    //一级分类查询
    const loadType = async (name) => {
      state.finished = false;
      state.oneTypeActive = name;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 1000,
      });
      if (state.oneTypeActive === 0) {
        state.showAdd = false
        //    state.allTypeCurrent = 1
        if (state.typeActive === 0) {
          state.showArticleType = false;
          let result = await getManageArticleList();
          state.articles = result.records;
          state.pages = result.pages;
        } else if (state.typeActive === state.endIndex) {
          state.showArticleType = false;
          let result = await getManageArticleList({
            types: state.articleTypeStr,
          });
          state.articles = result.records;
          state.pages = result.pages;
        } else {
          state.showArticleType = false;
          let result = await getManageArticleList({
            types: state.typeActive,
          });
          state.articles = result.records;
          state.pages = result.pages;
        }
        state.allTypeCurrent = 1;
        state.finished = false;
      } else {
        if (state.oneTypeActive === 1) {
          state.showAdd = false
          if (state.typeActive === 0) {
            state.showArticleType = false;
            let result = await getManageArticleList({ isMaterial: 1 });
            state.articles = result.records;
            state.pages = result.pages;
          } else if (state.typeActive === state.endIndex) {
            state.showArticleType = false;
            let result = await getManageArticleList({
              isMaterial: 1,
              types: state.articleTypeStr,
            });
            state.articles = result.records;
            state.pages = result.pages;
          } else {
            state.showArticleType = false;
            let result = await getManageArticleList({
              types: state.typeActive,
              isMaterial: 1,
            });
            state.articles = result.records;
            state.pages = result.pages;
          }
          state.isMaterialCurrent = 1;
          state.finished = false;
        }
        if (state.oneTypeActive === 2) {
          state.showAdd = true
          if (state.typeActive === 0) {
            state.showArticleType = false;
            let result = await getManageArticleList({ isMaterial: 0 });
            state.articles = result.records;
            state.pages = result.pages;
          } else if (state.typeActive === state.endIndex) {
            state.showArticleType = false;
            let result = await getManageArticleList({
              isMaterial: 0,
              // types: state.articleTypeStr,
            });
            state.articles = result.records;
            state.pages = result.pages;
          } else {
            state.showArticleType = false;
            let result = await getManageArticleList({
              // types: state.typeActive,
              isMaterial: 0,
            });
            state.articles = result.records;
            state.pages = result.pages;
          }

          state.notMaterialCurrent = 1;
          state.finished = false;
        }
      }
      Toast.clear();
    };
    //搜索
    const loadSearchArticle = () => {
      router.push({
        path: "/articleManagement/search",
        query: {
          artType: 1,
          oneType: state.oneTypeActive,
          twoType: state.typeActive,
        },
      });
    };

    //弹出
    const loadSelectType = () => {
      state.showType = !state.showType;
    };
    //取消搜索更多
    const loadCancelChoice = () => {
      state.box = [];
      state.articleTypeStr = "";
      state.showType = !state.showType;
    };
    const handleChoice = (it) => {
      if (state.box.includes(it)) {
        state.box.splice(state.box.indexOf(it), 1);
        state.articleTypeStr = state.articleTypeStr
          .split(`${it.categoryPrimaryId.toString()},`)
          .join("");
      } else {
        state.box.push(it);
        if (state.articleTypeStr === "") {
          state.articleTypeStr = it.categoryPrimaryId.toString() + ",";
        } else {
          state.articleTypeStr =
            state.articleTypeStr + it.categoryPrimaryId.toString() + ",";
        }
      }
    };

    //确认分类搜索
    const loadConfirm = async () => {
      state.articles = {};
      state.twoTypeTab = 0
      if (state.oneTypeActive === 0) {
        let result = await getManageArticleList({
          types: state.articleTypeStr,
        });
        state.articles = result.records;
      }
      if (state.oneTypeActive === 1) {
        let result = await getManageArticleList({
          types: state.articleTypeStr,
          isMaterial: 1,
        });
        state.articles = result.records;
      }
      if (state.oneTypeActive === 2) {
        let result = await getManageArticleList({
          // types: state.articleTypeStr,
          isMaterial: 0,
        });
        state.articles = result.records;
      }
      state.showType = !state.showType;
    };
    //返回
    const handleBlack = () => {
      router.push({ path: "/" });
    };
    //下拉刷新
    const onRefresh = async () => {
      if (state.oneTypeActive === 0) {
        if (state.typeActive === 0) {
          let result = await getManageArticleList();
          state.articles = result.records;
        } else if (state.typeActive === state.endIndex) {
          let result = await getManageArticleList({
            types: state.articleTypeStr,
          });
          state.articles = result.records;
          state.pages = result.pages;
        } else {
          let result = await getManageArticleList({
            types: state.typeActive,
          });
          state.articles = result.records;
          state.pages = result.pages;
        }
        state.allTypeCurrent = 1;
        state.finished = false;
      } else {
        if (state.oneTypeActive === 1) {
          if (state.typeActive === 0) {
            let result = await getManageArticleList({ isMaterial: 1 });
            state.articles = result.records;
          } else if (state.typeActive === state.endIndex) {
            let result = await getManageArticleList({
              types: state.articleTypeStr,
              isMaterial: 1,
            });
            state.articles = result.records;
            state.pages = result.pages;
          } else {
            let result = await getManageArticleList({
              types: state.typeActive,
              isMaterial: 1,
            });
            state.articles = result.records;
            state.pages = result.pages;
          }
          state.isMaterialCurrent = 1;
          state.finished = false;
        }

        if (state.oneTypeActive === 2) {
          if (state.typeActive === 0) {
            let result = await getManageArticleList({ isMaterial: 0 });
            state.articles = result.records;
            // state.pages = result.pages
          } else if (state.typeActive === state.endIndex) {
            let result = await getManageArticleList({
              // types: state.articleTypeStr,
              isMaterial: 0,
            });
            state.articles = result.records;
            state.pages = result.pages;
          } else {
            let result = await getManageArticleList({
              // types: state.typeActive,
              isMaterial: 0,
            });
            state.articles = result.records;
            state.pages = result.pages;
          }
          state.notMaterialCurrent = 1;
          state.finished = false;
        }
      }
      state.refreshing = false;
    };

    //加载更多
    const onLoadMore = async () => {
      //  state.loading = false
      if (state.oneTypeActive === 0) {
        if (state.typeActive === 0) {
          state.loading = true;
          state.allTypeCurrent += 1;
          if (state.allTypeCurrent > state.pages) {
            state.finished = true;
          } else {
            let result = await getManageArticleList({
              current: state.allTypeCurrent,
            });
            state.pages = result.pages;
            state.articles.push(...result.records);
            if (state.articles.length >= result.total) {
              state.finished = true;
            }
          }
          state.loading = false;
        } else if (state.typeActive === state.endIndex) {
          state.loading = true;
          state.allTypeCurrent += 1;
          if (state.allTypeCurrent > state.pages) {
            state.finished = true;
          } else {
            let result = await getManageArticleList({
              types: state.articleTypeStr,
              current: state.allTypeCurrent,
            });
            state.pages = result.pages;
            state.articles.push(...result.records);
            if (state.articles.length >= result.total) {
              state.finished = true;
            }
          }
          state.loading = false;
        } else {
          state.loading = true;
          state.allTypeCurrent += 1;
          if (state.allTypeCurrent > state.pages) {
            state.finished = true;
          } else {
            let result = await getManageArticleList({
              types: state.typeActive,
              current: state.allTypeCurrent,
            });
            state.pages = result.pages;
            state.articles.push(...result.records);
            if (state.articles.length >= result.total) {
              state.finished = true;
            }
          }
          state.loading = false;
        }
      } else {
        if (state.oneTypeActive === 1) {
          if (state.typeActive === 0) {
            state.loading = true;
            state.isMaterialCurrent += 1;
            if (state.isMaterialCurrent > state.pages) {
              state.finished = true;
            } else {
              let result = await getManageArticleList({
                current: state.isMaterialCurrent,
                isMaterial: 1,
              });
              state.pages = result.pages;
              state.articles.push(...result.records);
              if (state.articles.length >= result.total) {
                state.finished = true;
              }
            }
            state.loading = false;
          } else if (state.typeActive === state.endIndex) {
            state.loading = true;
            state.isMaterialCurrent += 1;
            if (state.isMaterialCurrent > state.pages) {
              state.finished = true;
            } else {
              let result = await getManageArticleList({
                isMaterial: 1,
                types: state.articleTypeStr,
                current: state.isMaterialCurrent,
              });
              state.pages = result.pages;
              state.articles.push(...result.records);
              if (state.articles.length >= result.total) {
                state.finished = true;
              }
            }
            state.loading = false;
          } else {
            state.loading = true;
            state.isMaterialCurrent += 1;
            if (state.isMaterialCurrent > state.pages) {
              state.finished = true;
            } else {
              let result = await getManageArticleList({
                types: state.typeActive,
                current: state.isMaterialCurrent,
              });
              state.pages = result.pages;
              state.articles.push(...result.records);
              if (state.articles.length >= result.total) {
                state.finished = true;
              }
            }

            state.loading = false;
          }
        }
        if (state.oneTypeActive === 2) {
          if (state.typeActive === 0) {
            state.loading = true;
            state.notMaterialCurrent += 1;
            if (state.notMaterialCurrent > state.pages) {
              state.finished = true;
            } else {
              let result = await getManageArticleList({
                current: state.notMaterialCurrent,
                isMaterial: 0,
              });
              state.pages = result.pages;
              state.articles.push(...result.records);
              if (state.articles.length >= result.total) {
                state.finished = true;
              }
            }
            state.loading = false;
          } else if (state.typeActive === state.endIndex) {
            state.loading = true;
            state.notMaterialCurrent += 1;
            if (state.notMaterialCurrent > state.pages) {
              state.finished = true;
            } else {
              let result = await getManageArticleList({
                isMaterial: 0,
                // types: state.articleTypeStr,
                current: state.notMaterialCurrent,
              });
              state.pages = result.pages;
              state.articles.push(...result.records);
              if (state.articles.length >= result.total) {
                state.finished = true;
              }
            }
            state.loading = false;
          } else {
            state.loading = true;
            state.notMaterialCurrent += 1;
            if (state.notMaterialCurrent > state.pages) {
              state.finished = true;
            } else {
              let result = await getManageArticleList({
                // types: state.typeActive,
                current: state.notMaterialCurrent,
              });
              state.pages = result.pages;
              state.articles.push(...result.records);
              if (state.articles.length >= result.total) {
                state.finished = true;
              }
            }
            state.loading = false;
          }
        }
      }
    };

    return {
      ...toRefs(state),
      loadCancelChoice,
      loadConfirm,
      handleChoice,
      handleBlack,
      loadSelectType,
      loadSearchArticle,
      onRefresh,
      loadType,
      loadArticleType,
      handleMoreType,
      onLoadMore,
    };
  },
};
</script>

<style lang="scss">
.container-articleManagment {
  //   overflow-x: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  //遮罩层
  //遮罩层
  .van-overlay {
    z-index: 3;
    height: 100%;
    width: 100%;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .select-like-type {
        position: absolute;
        height: 365px;
        width: 344px;
        left: 16px;
        top: 88px;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 8px;
        background-color: #fff;
        .cancel {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 12px;
          right: 12px;
        }
        .title {
          font-size: 18px;
          font-weight: 600;
          line-height: 10px;
        }
      }
      .grid-type {
        padding-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        // border-radius: 10px;
        justify-content: space-between;
        >i{
          height: 28px;
          width: 72px;
        }
        .grid-type-item {
          height: 28px;
          width: 72px;
          margin-bottom: 8px;
          border-radius: 5px;
          font-size: 12px;
          line-height: 28px;
          background-color: #f1f3f6;
        }
        .bgColor {
          color: #ffffff;
          background-color: #ffcf23;
        }
      }
      .confirm {
        position: absolute;
        height: 28px;
        width: 72px;
        color: #ffffff;
        font-size: 12px;
        line-height: 28px;
        background-color: #ff6e01;
        border-radius: 5px;
        margin-left: 50%;
        left: -36px;
        // transform:translateX(-50%);
        bottom: 20px;
      }
    }
  }
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }
  article {
    .articleType {
      padding: 0 16px;
      .van-tab {
        color: #666666;
        font-size: 18px;
        font-weight: 600;
        // flex: auto;
      }
      .van-tab--active {
        color: #323233;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .twotypeselect{
      display: flex;
      justify-content: space-between;
      .articleType-all {
        overflow-x: hidden;
        .van-tabs__wrap {
          border-bottom: 0.5px solid #f1f3f6;
        }
        .van-tab {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
          padding: 0 20px;
          //flex: auto;
          //   border-bottom: 1px solid #cccccc;
        }
        .van-tab--active {
          color: #323233;
          font-size: 12px;
          font-weight: 600;
        }
      }
      //.tabs-main{
      //  flex: 1;
      //  display: flex;
      //  width: 200px;
      //  overflow-y:hidden;
      //  li{
      //    width: auto;
      //    float: left;
      //  }
      //}
      .more-type{
        width: 120px;
        height: 50px;
        color: #666666;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        line-height: 45px;
        padding: 0 10px;
      }
    }
    //.articleType-all {
    //  .van-tab {
    //    color: #666666;
    //    font-size: 12px;
    //    font-weight: 600;
    //    flex: auto;
    //    border-bottom: 1px solid #cccccc;
    //  }
    //  .van-tab--active {
    //    color: #323233;
    //    font-size: 12px;
    //    font-weight: 600;
    //  }
    //}
  }

  .articleManagenent-type {
    flex: 1;
    overflow-x: hidden;
  }
}
</style>